<template>
  <div>
    <vl-region mod-no-space-top>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-title class="vl-u-spacer--none">{{ $t('payments.manual.label') }}</vl-title>
          </vl-column>
          <vl-column v-if="error">
            <vl-alert mod-error icon="info-circle">
              {{ error }}
            </vl-alert>
          </vl-column>
          <vl-column>
            <form @submit.prevent="onSubmit">
              <div class="vl-form-grid vl-form-grid--is-stacked">
                <div class="vl-form-col--2-12">
                  <label for="products">{{ $t('payments.manual.form.products') }}</label>
                </div>
                <div class="vl-form-col--10-12">
                  <v-select :options="products" label="name" v-model="selected"></v-select>
                </div>

                <div class="vl-form-col--2-12">
                  <label for="customerId">{{ $t('payments.manual.form.customerId') }}</label>
                </div>
                <div class="vl-form-col--10-12">
                  <input
                    type="text"
                    class="vl-input-field vl-input-field--block"
                    id="customerId"
                    v-model="payment.customerId"
                    required
                    placeholder="Enter Customer ID"
                  />
                </div>
                <div class="vl-form-col--2-12">
                  <label for="documentTitle">{{ $t('payments.manual.form.documentTitle') }}</label>
                </div>
                <div class="vl-form-col--10-12">
                  <input
                    type="text"
                    class="vl-input-field vl-input-field--block"
                    id="documentTitle"
                    :readonly="readOnly"
                    required
                    v-model="payment.documentTitle"
                    placeholder="Enter document Title"
                  />
                </div>
                <div class="vl-form-col--2-12">
                  <label for="communicationPart1">{{
                    $t('payments.manual.form.communication')
                  }}</label>
                </div>
                <div class="vl-form-col--10-12">
                  <input
                    type="text"
                    class="vl-input-field vl-input-field--block"
                    id="communicationPart1"
                    readonly
                    required
                    v-model="communicationPart1"
                    placeholder="Enter communication"
                  />
                </div>
                <div class="vl-form-col--2-12">
                  <label for="amount">{{ $t('payments.manual.form.amount') }}</label>
                </div>
                <div class="vl-form-col--10-12">
                  <input
                    type="number"
                    class="vl-input-field vl-input-field--block"
                    id="amount"
                    required
                    step=".01"
                    min="0.01"
                    :readonly="readOnly"
                    v-model="payment.amount"
                    placeholder="Enter amount"
                  />
                </div>
                <div class="vl-form-col--10-12 vl-form-push--2-12">
                  <button type="submit" class="vl-button">
                    {{ $t('payments.manual.form.submit') }}
                  </button>
                </div>
              </div>
            </form>
            <div class="overlay">
              <a target="_blank" :href="qrCode" class="parent" v-if="qrCode">
                <img class="pomTemplate" src="@/assets/pom.png" />
                <VueQrcode
                  class="qrCode"
                  v-if="qrCode"
                  :value="qrCode"
                  :options="{ width: 195 }"
                ></VueQrcode>
                <div class="link">
                  <p>{{ qrCode }}</p>
                </div>
              </a>
            </div>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  async created() {
    try {
      const user = this.$store.getters['app/user'];
      this.$store.dispatch('organization/get', user.organization.id).then(async response => {
        this.organization = response;
      });
      if (user.organization != null) {
        this.products = await this.$store.dispatch('product/getByTenant');
      }
      this.products.push(this.freeInput);
    } catch (err) {
      this.error = err.message;
    }
  },
  components: {
    VueQrcode,
  },
  data() {
    return {
      products: [],
      freeInput: { name: 'Free Input' },
      selected: 'Free Input',
      paymentLink: '',
      readOnly: false,
      qrCode: '',
      error: '',
      organization: {},
      payment: {
        customerId: '',
        documentTitle: '',
        amount: 0,
        productId: 0,
      },
    };
  },
  computed: {
    communicationPart1() {
      if (this.payment.customerId === '' && this.payment.documentTitle === '') {
        return '';
      }
      return this.payment.customerId + '_' + this.payment.documentTitle;
    },
  },
  methods: {
    async onSubmit() {
      try {
        if (!this.organization.orafin) {
          this.payment.communicationPart1 = this.communicationPart1;
        }
        this.$store
          .dispatch('payment/pay', this.payment)
          .then(response => {
            if (response.status !== undefined) {
              this.error = response.detail;
            } else {
              this.paymentLink = response.paylink;
              this.qrCode = response.additionalLinks.QR.paylinkShort;
            }
          })
          .catch(err => {
            this.error = err.message;
          });
      } catch (err) {
        this.error = err.message;
      }
    },
  },
  watch: {
    selected: function(product) {
      //do something when the data changes.
      if (product.name !== 'Free Input') {
        this.payment.documentTitle = product.titleCode;
        this.payment.amount = product.amount;
        this.payment.productId = product.id;
        this.readOnly = true;
      } else {
        this.payment.documentTitle = '';
        this.payment.amount = 0;
        this.readOnly = false;
      }
    },
  },
};
</script>

<style>
label {
  font-size: 1.5rem;
}
.red {
  color: red;
}
span,
li {
  font-size: 1.5rem;
}
.parent {
  margin-top: 10px;
  position: relative;
  top: 0;
  left: 0;
  display: table;
}
.pomTemplate {
  height: 260px;
  position: relative;
  top: 0;
  left: 0;
}
.qrCode {
  position: absolute;
  top: 5%;
  left: 67.4%;
}
.link {
  position: absolute;
  bottom: 35px;
  right: 38%;
}
p {
  text-align: right;
  font-size: 15px;
  color: black;
}
.overlay:hover {
  opacity: 0.5;
  transition: 0.2s;
}
.overlay {
  display: table;
  transition: 0.2s;
}
</style>
